<template>
  <div>
    <v-row justify="center">
      <v-col md="11">
        <h4 class="text-h4 checkout__step-header">
          {{ $t('fields.checkout.headline_step_1') }}
        </h4>

        <p class="checkout__step-description">
          {{ $t('fields.checkout.sub_headline_step_1') }}
        </p>

        <v-radio-group
          v-model="currentPaymentMethod"
          class="radio-selection"
          @change="(value) => $emit('paymentTypeChanged', value)"
        >
          <v-radio
            v-if="paymentMethodsToShow.includes('card')"
            :disabled="!isPaymentFormLoaded"
            value="card"
            color="navy"
            data-test="card-payment-method"
            class="radio-selection__type"
          >
            <template #label>
              <div class="d-inline-flex">
                {{ $t('fields.checkout.payment_methods.card') }}
              </div>
              <div class="d-inline-flex">
                <img class="ml-3" height="38" src="../../assets/images/payment-icons/pay-amex.svg" alt="" />
                <img class="ml-3" height="38" src="../../assets/images/payment-icons/pay-mastercard.svg" alt="" />
                <img class="ml-3" height="38" src="../../assets/images/payment-icons/pay-visa.svg" alt="" />
              </div>
            </template>
          </v-radio>
          <v-radio
            v-if="paymentMethodsToShow.includes('paypal')"
            :disabled="!isPaymentFormLoaded"
            value="paypal"
            color="navy"
            data-test="paypal-payment-method"
            class="radio-selection__type"
          >
            <template #label>
              <div class="d-inline-flex">
                {{ $t('fields.checkout.payment_methods.paypal') }}
              </div>
              <div class="d-inline-flex">
                <img
                  class="ml-3"
                  height="40"
                  width="100%"
                  src="../../assets/images/payment-icons/pay-paypal.svg"
                  alt=""
                />
              </div>
            </template>
          </v-radio>
          <v-radio
            v-if="paymentMethodsToShow.includes('apple_pay') && walletsAvailable.applePay"
            :disabled="!isPaymentFormLoaded"
            value="apple_pay"
            color="navy"
            data-test="apple-pay-payment-method"
            class="radio-selection__type"
          >
            <template #label>
              <div class="d-inline-flex">
                {{ $t('fields.checkout.payment_methods.apple_pay') }}
              </div>
              <div class="d-inline-flex">
                <img class="ml-3" height="40" src="../../assets/images/payment-icons/pay-apple-pay.svg" alt="" />
              </div>
            </template>
          </v-radio>

          <v-radio
            v-if="paymentMethodsToShow.includes('google_pay') && walletsAvailable.googlePay"
            :disabled="!isPaymentFormLoaded"
            value="google_pay"
            color="navy"
            data-test="google-pay-payment-method"
            class="radio-selection__type"
          >
            <template #label>
              <div class="d-inline-flex">
                {{ $t('fields.checkout.payment_methods.google_pay') }}
              </div>
              <div class="d-inline-flex">
                <img class="ml-3" height="40" src="../../assets/images/payment-icons/pay-google-pay.svg" alt="" />
              </div>
            </template>
          </v-radio>
          <v-radio
            v-if="paymentMethodsToShow.includes('send_invoice')"
            :disabled="!isPaymentFormLoaded"
            value="send_invoice"
            color="navy"
            data-test="bank-transfer-payment-method"
            class="radio-selection__type"
          >
            <template #label>
              <div class="d-inline-flex">
                {{ $t('fields.checkout.payment_methods.send_invoice') }}
              </div>
              <div class="d-inline-flex">
                <img class="ml-3" height="40" src="../../assets/images/payment-icons/pay-direct-debit.svg" alt="" />
              </div>
            </template>
          </v-radio>

          <v-radio
            v-if="isDirectDebitEnabled && paymentMethodsToShow.includes('sepa_debit')"
            :disabled="!isPaymentFormLoaded"
            value="sepa_debit"
            color="navy"
            class="radio-selection__type"
            data-test="sepa-payment-method"
          >
            <template #label>
              <div class="d-inline-flex">{{ $t('fields.checkout.payment_methods.sepa_debit') }}</div>
              <div class="d-inline-flex">
                <img class="ml-3" height="40" src="../../assets/images/payment-icons/pay-sepa.svg" alt="" />
              </div>
            </template>
          </v-radio>
        </v-radio-group>

        <v-row v-if="paymentMethodsLoading">
          <v-col cols="12">
            <v-skeleton-loader loading type="text" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { types } from 'sass';
import { Plan } from '@/classes/stripe';
import Boolean = types.Boolean;

@Component
export default class PaymentMethod extends Vue {
  @Prop({ type: Plan })
  selectedPlan!: Plan;
  @Prop({ type: Array })
  paymentMethodsToShow!: Array<string>;
  @Prop({ type: String })
  selectedPaymentType!: string;
  @Prop({ type: Boolean })
  isPaymentFormLoaded!: boolean;
  @Prop({ type: Boolean })
  paymentMethodsLoading!: boolean;
  isDirectDebitEnabled: boolean = process.env.VUE_APP_FEATURE_SEPA === 'true';
  currentPaymentMethod: string = this.selectedPaymentType;

  @Prop({ type: Object, default: false })
  walletsAvailable!: object;
}
</script>
