import AppConfig from '@/AppConfig';
import OptimizelyService from '@/Services/OptimizelyService';
import type StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import type { StripePaymentMethodService } from '@/Services/Subscription/StripePaymentMethodService';
import axios from 'axios';

export async function orchestratePaypalFlow(
  stripeCheckoutService: StripeCheckoutService,
  stripePaymentMethodService: StripePaymentMethodService,
  selectedPlan: string,
  selectedPlanId: string,
  checkoutType: string
): Promise<void> {
  // save the payment method and create a customer in stripe
  if (OptimizelyService.isUserOnAbtestVariantCNV2936() && stripeCheckoutService.customer.address.country === '') {
    stripeCheckoutService.customer.address.country = stripeCheckoutService.location.country_iso2;
  }

  await stripePaymentMethodService.savePaymentMethod({
    legal_entity: stripeCheckoutService.legalEntity,
    customer: stripeCheckoutService.customer,
  });

  // create a setup intent
  const { data: setupIntent } = await axios({
    method: 'post',
    url: AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods/create',
    data: {
      payment_method_type: 'paypal',
    },
  });

  // persist the desired plan for activation after a successful payment
  await axios({
    method: 'post',
    url: AppConfig.getAPIBaseUrl() + '/team/incomplete-subscription/create',
    data: {
      plan_id: selectedPlanId,
      setup_intent_id: setupIntent.id,
    },
  });

  // authorize the setup in paypal
  const redirectUrl = `${AppConfig.getAppUrl()}/processing?checkoutType=${checkoutType}&plan=${selectedPlan.toLowerCase()}&planId=${selectedPlanId}`;

  const { error } = await stripePaymentMethodService.stripe.confirmPayPalSetup(setupIntent.client_secret, {
    return_url: redirectUrl,
    mandate_data: {
      customer_acceptance: {
        type: 'online',
        online: {
          infer_from_client: true,
        },
      },
    },
  });

  if (error) {
    throw new Error(error.message);
  }
}
