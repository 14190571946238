<template>
  <v-row v-if="activePlan || invoices.length" class="mt-9 transaction-history">
    <v-col sm="12">
      <!-- Title -->
      <h4 class="text-h4 mb-1">
        {{ $t('fields.account.billing.transactions.title') }}
      </h4>

      <!-- HISTORY TABLE -->
      <v-data-table
        :headers="historyTableData.headers"
        :items="invoices"
        hide-default-footer
        disable-pagination
        class="qr-data-table--history"
        :loading="isInvoicesLoading"
        :loading-text="$t('fields.actions.loading')"
        :mobile-breakpoint="768"
        data-test="invoicesTable"
      >
        <template #item="{ item, isMobile, headers }">
          <tr
            :class="[
              getHistoryRowClass(item.status, item.original_status),
              isMobile ? 'qr-data-table__mobile__tr' : null,
            ]"
          >
            <template v-if="item.status === 'pending'">
              <td class="text-start">
                <span class="text-uppercase text-overline--small">
                  {{ getStatusName(item.status, item.original_status) }}
                </span>
              </td>
              <td class="text-start">
                <span class="font-weight-semi-bold navy--text">
                  {{ $t('fields.account.billing.transactions.status_pending_being_created') }}
                </span>
              </td>
              <td colspan="4">
                <span class="font-italic lapis--text text--lighten-2">
                  {{ $t('fields.account.billing.transactions.status_pending_being_created_description') }}
                </span>
              </td>
            </template>
            <template v-else>
              <td class="text-start" :data-label="headers[0].text">
                <span class="text-uppercase text-overline--small">
                  {{ getStatusName(item.status, item.original_status) }}
                </span>
              </td>
              <td class="text-start" :data-label="headers[1].text">
                <span class="font-weight-semi-bold">
                  {{ item.number }}
                </span>
              </td>
              <td class="text-start" :data-label="headers[2].text">
                <span v-if="stripeSubscriptionService.findPlan(item.plan)" class="lapis--text text--lighten-2">
                  {{ $t(`fields.${stripeSubscriptionService.findPlan(item.plan).translationKey}`) }}
                </span>
                <span v-else class="text--disabled"> — </span>
              </td>
              <td class="text-start" :data-label="headers[3].text">
                <span class="lapis--text text--lighten-2">
                  {{ dayjs.unix(item.created).format('MMM DD, YYYY') }}
                </span>
              </td>
              <td class="text-start" :data-label="headers[4].text">
                <span class="lapis--text text--lighten-2 font-weight-semi-bold">
                  {{ formatInvoiceTotal(item.amount_due) }}
                </span>
              </td>
              <td class="text-end">
                <!-- Pay button -->
                <v-btn
                  v-if="item.provider === 'stripe' && item.status === 'not_paid' && item.original_status === 'open'"
                  rounded
                  depressed
                  :ripple="false"
                  color="primary"
                  small
                  class="mr-4"
                  :href="item.hosted_invoice_url"
                  target="_blank"
                >
                  {{ $t('fields.account.billing.transactions.pay') }}
                </v-btn>
                <!-- Download button -->
                <v-btn icon :ripple="false" color="primary" :href="item.invoice_pdf" target="_self">
                  <Icon name="download-system" outline />
                </v-btn>
              </td>
            </template>
          </tr>
        </template>
        <template #no-data>
          <Alert type="error" icon="forbidden-system" inline regular-title small>
            <template #title>
              <span class="font-weight-semi-bold">{{ $t('fields.account.billing.payment.payment_method_error') }}</span>
              <v-btn text :ripple="false" color="primary" x-small class="ml-1" @click="fetchInvoices">
                <span class="navy--text">
                  {{ $t('fields.account.billing.payment.try_again') }}
                </span>
              </v-btn>
            </template>
          </Alert>
        </template>
      </v-data-table>
      <!-- HISTORY TABLE END -->
    </v-col>
  </v-row>
</template>
<script lang="ts">
interface TableData {
  headers: {
    text: string;
    align?: string;
    sortable: boolean;
    value: string;
    class?: string;
    width?: string;
  }[];
}
import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import type { Invoice } from '@/classes/stripe';
import Alert from '@/components/Alert.vue';
import Icon from '@/components/Icons/Icon.vue';
import store from '@/store';
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
    Alert,
  },
})
export default class Invoices extends Vue {
  dayjs = dayjs;
  activePlan = store.state?.subscription?.activePlan || {};
  stripeSubscriptionService = new StripeSubscriptionService();

  async mounted(): Promise<void> {
    this.fetchInvoices();
  }

  async fetchInvoices(): Promise<void> {
    store.dispatch('account/loadInvoices');
  }

  get invoices(): Invoice[] {
    return this.$store.state?.account.invoices || [];
  }

  get isInvoicesLoading(): boolean {
    return this.$store.state?.account.loading;
  }

  get historyTableData(): TableData {
    return {
      headers: [
        {
          text: this.$t('fields.account.billing.transactions.header_status').toString(),
          align: 'start',
          sortable: false,
          value: 'status',
          class: 'qr-data-table__header',
          width: 'auto',
        },
        {
          text: this.$t('fields.account.billing.transactions.header_invoice_nr').toString(),
          align: 'start',
          sortable: false,
          value: 'number',
          class: 'qr-data-table__header',
        },
        {
          text: this.$t('fields.account.billing.transactions.header_plan').toString(),
          align: 'start',
          sortable: false,
          value: 'plan',
          class: 'qr-data-table__header',
        },
        {
          text: this.$t('fields.account.billing.transactions.header_date').toString(),
          align: 'start',
          sortable: false,
          value: 'date',
          class: 'qr-data-table__header',
        },
        {
          text: this.$t('fields.account.billing.transactions.header_total').toString(),
          align: 'start',
          sortable: false,
          value: 'total',
          class: 'qr-data-table__header',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  }

  getHistoryRowClass(status: string, originalStatus: string): { [key: string]: boolean } {
    let classPrefix = 'qr-data-table__payment--status';
    return {
      [`${classPrefix}--paid`]: status === 'paid',
      [`${classPrefix}--not-paid`]: status === 'not_paid',
      [`${classPrefix}--refunded`]: status === 'refunded',
      [`${classPrefix}--pending`]: status === 'pending',
      [`${classPrefix}--pending`]: originalStatus === 'void',
    };
  }

  getStatusName(status: string, originalStatus: string): string {
    if (originalStatus === 'void') {
      return this.$t('fields.account.billing.transactions.status_void').toString();
    }

    switch (status) {
      case 'paid':
        return this.$t('fields.account.billing.transactions.status_paid').toString();
      case 'not_paid':
        return this.$t('fields.account.billing.transactions.status_not_paid').toString();
      case 'refunded':
        return this.$t('fields.account.billing.transactions.status_refunded').toString();
      case 'pending':
        return this.$t('fields.account.billing.transactions.status_pending').toString();
      default:
        return '';
    }
  }

  formatInvoiceTotal(price: number): string {
    const formatPrice = price / 100;
    const currencyModel = new Currency();
    currencyModel.price = formatPrice;
    return currencyModel.displayPrice({ price: currencyModel.price, useGrouping: true });
  }
}
</script>
