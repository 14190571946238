<template>
  <div id="pricing-page">
    <!-- Payment notice -->
    <v-row v-if="!isEnterprise && !loadingPlans && !loadingSubscription && selectedCurrency !== 'eur'">
      <v-col xs="12" class="d-flex justify-center">
        <Alert dense outlined type="info" icon="info-system" regular-title max-width="1024">
          <template #title>
            <span v-html="$t('fields.pricing_page.currency_alert')" />
          </template>
        </Alert>
      </v-col>
    </v-row>
    <!-- Payment notice END -->

    <!-- Page-Header -->
    <v-row v-if="!isUserOnAbtestVariantCNV3020()">
      <v-col xs="12">
        <h1 class="pricing__header text-h1 mt-6">{{ $t('fields.pricing_page.page_title') }}</h1>
        <h5 class="pricing__subheader text-h5">
          {{ $t('fields.pricing_page.page_subtitle') }}
        </h5>
      </v-col>
    </v-row>

    <CommonFeatures v-else />
    <!-- Page-Header END -->

    <!-- LAYOUT LOADING -->
    <template v-if="loadingSubscription || loadingPlans">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate :width="3" color="candy" />
        </v-col>
      </v-row>
    </template>
    <!-- LAYOUT LOADING END -->

    <!-- Enterprise user -->
    <v-row v-if="isEnterprise && !loadingPlans && !loadingSubscription" class="mt-11">
      <v-col lg="10" offset-lg="1" class="py-0">
        <v-row align="stretch" justify="center">
          <v-col class="pt-0" sm="12">
            <EnterprisePlan />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Enterprise user END -->
    <template v-if="!isEnterprise && !loadingPlans && !loadingSubscription">
      <!-- Currency-selection -->
      <v-row align="center" justify="center">
        <v-col
          v-if="isUserOnFlexplanVariantCorD()"
          class="d-flex col-12 col-sm-8 col-lg-3 offset-lg-4 justify-center justify-sm-end justify-lg-center"
        >
          <PlanTypeToggle
            v-model="selectedPlanType"
            :plan-type="selectedPlanType"
            @plan-type-change="onPlanTypeChange"
          />
        </v-col>
        <v-col
          :sm="isUserOnFlexplanVariantCorD() ? 4 : 10"
          :xl="isUserOnFlexplanVariantCorD() ? 4 : 8"
          class="pb-0 pr-xl-12"
        >
          <v-row
            :class="
              isUserOnFlexplanVariantCorD()
                ? 'pricing__currency-select-wrapper--abTest'
                : 'pricing__currency-select-wrapper'
            "
          >
            <v-col cols="auto">
              <v-select
                v-if="['eur'].includes(team.currency)"
                v-model="selectedCurrency"
                :items="currencies"
                item-value="key"
                item-text="value"
                hide-details
                append-icon="$chevron-system-small-outline"
                filled
                dense
                :menu-props="{
                  contentClass: 'menu--select',
                  bottom: true,
                  offsetY: true,
                  nudgeBottom: 0,
                }"
                class="qr-select qr-select--rounded"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Currency-selection END -->

      <!-- Header Pricing -->
      <HeaderPlanTile
        v-if="showHeaderPlanTile && headerPlan !== undefined"
        :plan="headerPlan"
        :currency-iso2="selectedCurrency"
        :stripe-subscription-service="stripeSubscriptionService"
        :loading="isLoading"
      />
      <!-- Header Pricing END -->

      <!-- Pricing table -->
      <v-row>
        <v-col
          :lg="isUserOnFlexplanVariantCorD() ? 12 : 10"
          xl="10"
          :offset-lg="isUserOnFlexplanVariantCorD() ? 0 : 1"
          offset-xl="1"
          class="py-0"
        >
          <v-row align="stretch" justify="center">
            <v-col
              v-for="plan in gridPlans"
              :key="plan.id"
              class="pt-0"
              :class="[
                'general-class',
                {
                  'flexplan-abtest-variant-b': isUserOnFlexplanVariantB(),
                  'flexplan-abtest-variant-c': isUserOnFlexplanVariantC(),
                  'flexplan-abtest-variant-d': isUserOnFlexplanVariantD(),
                  'pricing__pricing-table--most-popular': showMostPopular(plan),
                  'pricing__pricing-table--best-value': showBestValue(plan),
                  'pricing__pricing-table--best-value-starter': plan.isStarterPlan && isUserOnFlexplanVariantCorD(),
                  'pricing__pricing-table--flexplan': plan.isStarterFlexPlan,
                  'pricing__pricing-table--starter': plan.isStarterPlan,
                  'pricing__pricing-table--advanced': plan.planLevel === '2',
                  'pricing__pricing-table--professional': plan.planLevel === '3',
                },
              ]"
              cols="12"
              sm="12"
              :md="isUserOnFlexplanVariantCorD() ? 12 : 4"
              :lg="isUserOnFlexplanVariantCorD() ? 3 : 4"
              xl="3"
            >
              <PlanTile
                :plan="plan"
                :currency-iso2="selectedCurrency"
                :stripe-subscription-service="stripeSubscriptionService"
                :loading="isLoading"
                :best-value-savings="bestValueSavings(plan)"
                :disable-plan-tile="isUserOnFlexplanVariantCorD() && isPlanTileDisabled(plan)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Pricing table END -->

      <!-- Pricing table subtext -->
      <v-row align="center" justify="center">
        <v-col md="10" class="pricing__sub-note">
          <span v-if="!isUserOnAbtestVariantCNV3020()">{{ $t('fields.pricing_table.additional_features') }}</span>
          <br />
          {{ $t('fields.pricing_table.excluded_tax') }}
        </v-col>
      </v-row>
      <!-- Pricing table subtext END -->

      <!-- START: Enterprise promo -->
      <v-row class="pricing__enterprise container-fluid">
        <v-col cols="12" class="pa-0">
          <h3 class="text-h3 text-center">
            {{ $t('fields.pricing_page.enterprise_ad.title') }}
          </h3>
        </v-col>
        <v-col cols="12" class="pa-0">
          <p class="enterprise__text">
            {{ $t('fields.pricing_page.enterprise_ad.description') }}
          </p>
        </v-col>
        <v-col cols="12" class="text-center pa-0">
          <v-btn text large color="primary" class="mt-0" target="_blank" :ripple="false" :href="getInTouchLink">
            <span class="navy--text">{{ $t('fields.pricing_page.enterprise_ad.button') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Enterprise promo END -->
    </template>

    <!-- FAQ -->
    <v-row>
      <v-col lg="10" offset-lg="1" class="pb-0">
        <v-row class="pricing__faq">
          <v-col cols="12">
            <h3 class="text-h3 text-center mb-5">
              {{ $t('fields.pricing_page.enterprise_ad.button') }}
            </h3>
          </v-col>
          <v-col cols="12" lg="8">
            <v-expansion-panels class="faq__list" accordion :value="0">
              <v-expansion-panel
                v-for="faqEntry in faqList"
                :key="faqEntry.question"
                :data-test="faqEntry.freePlanAddition ? 'pricing-page-faq-entry-free-plan' : 'pricing-page-faq-entry'"
              >
                <v-expansion-panel-header class="faq__question" expand-icon="$chevron-system-small-outline">
                  {{ faqEntry.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="faq__answer">
                  {{ faqEntry.answer }}
                  <div v-if="faqEntry.freePlanAddition && accountIsTrial && !accountHasFreePlan">
                    {{ faqEntry.freePlanAddition }}
                    <v-btn
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      class="v-btn--text--link"
                      data-test="pricing-page-activate-free-plan"
                      @click.stop="clickOnActivateFreePlan"
                    >
                      <span class="navy--text">{{ $t('fields.pricing_page.faq.free_plan_activate_button') }}</span>
                    </v-btn>

                    <downgrade-restricted-modal
                      :open="openDowngradeRestrictedModal"
                      :user-code-amount="qrcodes.current"
                      @cancel="onDowngradeRestrictedModalCancel"
                    />

                    <select-free-plan-modal :open="openSelectFreePlanModal" @cancel="onSelectFreePlanModalCancel" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12" lg="4">
            <v-row>
              <v-col cols="12" sm="6" lg="12" class="faq__bubble-wrapper">
                <div class="faq__speech-bubble">
                  <h5 class="text-h5 mb-3">
                    {{ $t('fields.pricing_page.faq.bubble_header') }}
                  </h5>
                  <v-btn
                    text
                    :ripple="false"
                    color="primary"
                    small
                    href="https://help.qr-code-generator.com/en"
                    target="_blank"
                  >
                    <span class="navy--text">{{ $t('fields.pricing_page.faq.bubble_helpcenter') }}</span>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12" sm="6" lg="12" class="faq__illustration-wrapper">
                <img src="../../assets/illustrations/pricing-faq_illustration_exported.png" alt="" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- FAQ END -->
  </div>
</template>

<script lang="ts">
import { Currencies, CurrencySymbols } from '@/Models/Localization';
import KeyValueItem from '@/Models/PrimitiveModels/KeyValueItem';
import EnterprisePlan from '@/Pages/PricingPage/EnterprisePlan.vue';
import PlanTile from '@/Pages/PricingPage/PlanTile.vue';
import HeaderPlanTile from '@/Pages/PricingPage/HeaderPlanTile.vue';
import EventService from '@/Services/EventService';
import OptimizelyService from '@/Services/OptimizelyService';
import { filterAndSortPlans } from '@/Services/Subscription/PlanViewService';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import { Team, User } from '@/classes/auth';
import { Plan, Subscription, SubscriptionState } from '@/classes/stripe';
import Alert from '@/components/Alert.vue';
import CommonFeatures from '@/components/CommonFeatures.vue';
import DowngradeRestrictedModal from '@/components/Modals/Account/DowngradeRestrictedModal.vue';
import SelectFreePlanModal from '@/components/Modals/Account/SelectFreePlanModal.vue';
import { SupportModalMixin } from '@/mixins/SupportModalMixin';
import store from '@/store';
import { AccountState } from '@/store/account';
import { Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import PlanTypeToggle from '@/Pages/PricingPage/PlanTypeToggle.vue';

@Component({
  components: {
    PlanTypeToggle,
    DowngradeRestrictedModal,
    SelectFreePlanModal,
    EnterprisePlan,
    PlanTile,
    HeaderPlanTile,
    Alert,
    CommonFeatures,
    OptimizelyService,
  },
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      subscriptionPlans: (state: SubscriptionState) => state.plans,
      loadingPlans: (state: SubscriptionState) => state.showLoading.loadingPlans,
      loadingSubscription: (state: SubscriptionState) => state.showLoading.loadingSubscription,
      activeSubscription: (state: SubscriptionState) => state.activeSubscription,
    }),
    ...mapState<AccountState>('account', {
      account: (state: AccountState) => state.account,
      qrcodes: (state: AccountState) => state.account?.qrcodes || [],
    }),
    ...mapGetters('auth', {
      user: 'user',
      team: 'team',
    }),
    ...mapGetters('subscription', {
      isEnterprise: 'isEnterprise', // <- for runtime
    }),
  },
})
export default class PlanSelection extends SupportModalMixin {
  // state from vuex
  user!: User;
  team!: Team;
  loadingPlans!: boolean;
  loadingSubscription!: boolean;
  subscription: Subscription = new Subscription();
  subscriptionPlans!: Array<Plan>;
  activeSubscription!: Subscription;

  openDowngradeRestrictedModal = false;
  openSelectFreePlanModal = false;
  getInTouchLink = 'https://form.asana.com/?k=0FszAJp6r2L5vtEiSrj9Ag&d=1174264937396';

  account!: any;
  qrcodes!: any;

  // List of currencies to choose from to preview different currency exchange-rates later on
  currencies: Array<KeyValueItem> = [
    {
      key: Currencies.EUR,
      value: `${CurrencySymbols.EUR}EUR`,
    },
    {
      key: Currencies.USD,
      value: `$USD`,
    },
    {
      key: Currencies.GBP,
      value: `${CurrencySymbols.GBP}GBP`,
    },
  ];

  // Limits of this plan as a clean list, null values won't be shown
  faqList: Array<{ question: string; answer: string; freePlanAddition?: string }> = [];

  // Which currency exchange-rates shall we gonna display to the customer
  selectedCurrency = Currencies.EUR;

  // Used to display active subscription state inside plan selection
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();

  // Loading indicator
  isLoading = false;

  // getter from vuex
  isEnterprise!: boolean;

  selectedPlanType: 'annual' | 'flexible' = 'annual';

  mounted(): void {
    this.selectedPlanType = OptimizelyService.isFlexPlanAbTestVariant3Enabled(this.user) ? 'flexible' : 'annual';

    this.faqList = [
      {
        question: this.$t('fields.pricing_page.faq.question1').toString(),
        answer: this.$t('fields.pricing_page.faq.answer1').toString(),
      },
      {
        question: this.$t('fields.pricing_page.faq.question2').toString(),
        answer: this.$t('fields.pricing_page.faq.answer2').toString(),
      },
      {
        question: this.$t('fields.pricing_page.faq.question3').toString(),
        answer: this.$t('fields.pricing_page.faq.answer3').toString(),
      },
      {
        question: this.$t('fields.pricing_page.faq.question4').toString(),
        answer: this.$t('fields.pricing_page.faq.answer4').toString(),
      },
      {
        question: this.$t('fields.pricing_page.faq.question5').toString(),
        answer: this.$t('fields.pricing_page.faq.answer5').toString(),
        freePlanAddition: this.$t('fields.pricing_page.faq.free_plan_info').toString(),
      },
    ];
  }

  async created(): Promise<void> {
    this.isLoading = true;
    await this.stripeSubscriptionService.initSubscription();
    this.isLoading = false;

    if (this.$route.query.trial === 'expired') {
      this.showTrialExpiredBanner();
    }

    EventService.createEvent(EventService.VISITED_PRICING);
  }

  showTrialExpiredBanner(): void {
    store.dispatch('layout/setSnackbar', {
      active: true,
      type: 'error',
      icon: 'warning-system',
      text: this.$t('fields.pricing_page.trial-expired-title').toString(),
      isSnackbar: false,
      sticky: true,
      showDismissButton: false,
      textClass: 'trial-expired-title',
    });
  }

  get accountIsTrial(): boolean {
    return !!this.account?.trial;
  }

  get accountHasFreePlan(): boolean {
    return this.account?.type === 'free';
  }

  get returnPlans(): Array<Plan> {
    return filterAndSortPlans(this.subscriptionPlans);
  }

  get showHeaderPlanTile(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariant1Enabled(this.user);
  }

  get headerPlan(): Plan | undefined {
    return this.returnPlans.find((plan) => plan.isStarterFlexPlan);
  }

  get gridPlans(): Array<Plan> {
    // If A/B test is active, we make sure to also show the flex plan in the grid
    if (OptimizelyService.isFlexPlanAbTestVariantCorD(this.user)) {
      return this.returnPlans;
    }

    return this.returnPlans.filter((plan) => !plan.isStarterFlexPlan);
  }

  showBestValue(plan: Plan): boolean {
    return OptimizelyService.isFlexPlanAbTestVariant1Enabled(store.state?.auth?.user) && plan.isStarterPlan;
  }

  bestValueSavings(toPlan: Plan): number | undefined {
    if (toPlan.isStarterPlan && this.headerPlan) {
      return this.calculatePercentageSavings(this.headerPlan, toPlan);
    }

    return undefined;
  }

  isUserOnAbtestVariantCNV3020(): boolean {
    return OptimizelyService.isUserOnAbtestVariantCNV3020();
  }

  isUserOnFlexplanVariantB(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariant1Enabled(this.user);
  }

  isUserOnFlexplanVariantC(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariant2Enabled(this.user);
  }

  isUserOnFlexplanVariantD(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariant3Enabled(this.user);
  }

  isUserOnFlexplanVariantCorD(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariantCorD(this.user);
  }

  showMostPopular(plan: Plan): boolean {
    return plan.popular && !OptimizelyService.isFlexPlanABTestEnabled(this.user);
  }

  calculatePercentageSavings(fromPlan: Plan, toPlan: Plan): number {
    let totalFromPrice = fromPlan.price.priceToInt();
    let totalToPrice = toPlan.price.priceToInt();

    if (totalFromPrice <= 0 || totalToPrice <= 0) {
      return 0;
    }

    if (fromPlan.interval === 'month') {
      const payments = 12 / fromPlan.interval_count;
      totalFromPrice *= payments;
    }

    if (toPlan.interval === 'month') {
      const payments = 12 / toPlan.interval_count;
      totalToPrice *= payments;
    }

    const savings = totalFromPrice - totalToPrice;
    const percentageSavings = (savings / totalFromPrice) * 100;

    return Math.round(percentageSavings);
  }

  /**
   * If the user can select a free plan, he will see the modal to proceed
   * If the user can't select a free plan, he will see a restriction modal
   */
  clickOnActivateFreePlan(): void {
    if (this.stripeSubscriptionService.canSelectFreePlan) {
      this.openSelectFreePlanModal = true;
    } else {
      this.openDowngradeRestrictedModal = true;
    }
  }

  /**
   * Called when a user clicks cancel in downgrade-restricted modal
   */
  onDowngradeRestrictedModalCancel(): void {
    this.openDowngradeRestrictedModal = false;
  }

  /**
   * Called when a user clicks cancel in free plan modal
   */
  onSelectFreePlanModalCancel(): void {
    this.openSelectFreePlanModal = false;
  }

  onPlanTypeChange(newPlanType: 'annual' | 'flexible') {
    this.selectedPlanType = newPlanType;
  }

  isPlanTileDisabled(plan: Plan): boolean {
    return (
      (plan.isStarterFlexPlan && this.selectedPlanType === 'annual') ||
      (!plan.isStarterFlexPlan && this.selectedPlanType === 'flexible')
    );
  }
}
</script>

<style lang="scss">
#pricing-page {
  .pricing__pricing-table--most-popular,
  .pricing__pricing-table--best-value,
  .pricing__pricing-table--best-value-starter {
    @media (max-width: 959px) {
      margin-top: 30px;
    }
  }
  .pricing {
    &__currency-select-wrapper--abTest {
      justify-content: flex-end;
    }

    &__currency-select-wrapper {
      justify-content: flex-end;
      @media (max-width: 959px) {
        justify-content: center;
      }
    }

    &__pricing-table--best-value-flex {
      @media (max-width: 599px) {
        margin-top: 30px;
      }
    }

    &__header {
      text-align: center;
    }

    &__subheader {
      text-align: center;
      color: map-get($lapis, 'lighten-2');
      margin-top: 12px;
    }

    &__sub-note {
      padding: 0 12px;
      text-align: center;
      font-size: 12px;
      color: map-get($lapis, 'lighten-2');
    }

    // Enterprise ad
    &__enterprise {
      padding: 48px 12px;
      background: url('../../assets/illustrations/bg_image_enterprise.svg') no-repeat center;
      width: 100vw;
      position: relative;
      margin: 16px 0 16px -50vw;
      left: 50%;
      height: 264px;
      display: flex;
      justify-content: center;

      .enterprise {
        &__text {
          text-align: center;
          max-width: 528px;
          margin: 0 auto;
          color: map-get($lapis, 'lighten-2');
        }
      }
    }
  }

  // FAQ section
  .faq {
    &__list,
    &__speech-bubble {
      border: 2px solid map-get($neutral, 'lighten-2');
      border-radius: 12px;
    }

    &__question {
      font-size: 18px;
      line-height: 24px;
      color: map-get($lapis, 'lighten-1');
      font-weight: map-get($font-weights, 'semi-bold');
      padding: 12px 20px;

      &:not(.v-expansion-panel-header--active) {
        &:hover {
          background: rgba(map-get($primary, 'base'), 0.08);
        }

        &:focus {
          background: rgba(map-get($primary, 'base'), 0.16);
        }

        &:hover,
        &:focus {
          svg {
            color: map-get($primary, 'base');
          }
        }
      }

      svg {
        color: map-get($neutral, 'base');
      }
    }

    &__answer {
      color: map-get($lapis, 'lighten-2');
      font-size: 12px;
      line-height: 18px;

      .v-expansion-panel-content__wrap {
        padding: 0 20px 16px;
      }
    }

    &__speech-bubble {
      padding: 16px 20px;
    }

    &__illustration-wrapper {
      @media (min-width: 600px) and (max-width: 1263px) {
        display: flex;
        justify-content: center;
      }
    }

    &__bubble-wrapper {
      padding-top: 0;

      @media (min-width: 600px) and (max-width: 1263px) {
        order: 2;
      }
    }
  }
}
.v-snack {
  &__wrapper,
  &__content {
    width: auto;
    max-width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 1263px) {
  .flexplan-abtest-variant-c,
  .flexplan-abtest-variant-d {
    &.pricing__pricing-table--flexplan {
      order: -4;
    }
    &.pricing__pricing-table--starter {
      order: -3;
    }
    &.pricing__pricing-table--advanced {
      order: -2;
    }
    &.pricing__pricing-table--professional {
      order: -1;
    }
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .flexplan-abtest-variant-b {
    &.pricing__pricing-table--flexplan {
      order: -4;
    }
    &.pricing__pricing-table--starter {
      order: -3;
    }
    &.pricing__pricing-table--advanced {
      order: -2;
    }
    &.pricing__pricing-table--professional {
      order: -1;
    }
  }
}
@media (max-width: 599px) {
  .flexplan-abtest-variant-b {
    &.pricing__pricing-table--flexplan {
      order: -4;
    }
    &.pricing__pricing-table--starter {
      order: -1;
    }
    &.pricing__pricing-table--advanced {
      order: -2;
    }
    &.pricing__pricing-table--professional {
      order: -3;
    }
  }
  .flexplan-abtest-variant-c {
    &.pricing__pricing-table--flexplan {
      order: -1;
    }
    &.pricing__pricing-table--starter {
      order: -2;
    }
    &.pricing__pricing-table--advanced {
      order: -3;
    }
    &.pricing__pricing-table--professional {
      order: -4;
    }
  }
  .flexplan-abtest-variant-d {
    &.pricing__pricing-table--flexplan {
      order: -4;
    }
    &.pricing__pricing-table--starter {
      order: -3;
    }
    &.pricing__pricing-table--advanced {
      order: -2;
    }
    &.pricing__pricing-table--professional {
      order: -1;
    }
  }
}
</style>
