import { CurrencyCode } from '@/Models/Localization';
import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import dayjs from 'dayjs';

export class Subscription {
  id: any;
  status: string;
  plan_id: string;
  plan: Plan;
  new_plan_id: string;
  is_on_invoice: boolean;
  is_cancelled: boolean;
  is_active: boolean;
  ends_at: dayjs.Dayjs | null;
  updated_at: dayjs.Dayjs;
  current_period_end: dayjs.Dayjs;
  instance: string;

  constructor(object: any = {}) {
    this.id = object.id || 0;
    this.status = object?.status || '';
    this.plan_id = object?.plan_id || '';
    this.plan = object?.plan ?? new Plan();
    this.new_plan_id = object?.new_plan_id || '';
    this.is_on_invoice = !!object?.is_on_invoice || false;
    this.is_cancelled = !!object?.is_cancelled || false;
    this.is_active = !!object?.is_active || false;
    this.ends_at = object.ends_at ? dayjs(object.ends_at) : null;
    this.updated_at = dayjs(object?.updated_at);
    this.current_period_end = dayjs(object?.current_period_end);
    this.instance = object?.instance || null;
  }
}

export type PaymentMethod = PaypalPaymentMethod | CardPaymentMethod | SupportedPaymentMethod;

export interface SupportedPaymentMethod {
  type: 'card' | 'paypal' | 'sepa_debit';
  is_default: boolean;
}

export interface PaypalPaymentMethod extends SupportedPaymentMethod {
  type: 'paypal';
  email: string;
}

export interface CardPaymentMethod extends SupportedPaymentMethod {
  type: 'card';
  brand: string;
  exp_month: string;
  exp_year: string;
  last4: string;
}

export class Plan {
  id: any;
  title: string;
  subtitle: string;
  codeLimit: string;
  scanLimit: string;
  product_id: string;
  bulkLimit: string | null;
  apiLimit: string | null;
  userLimit: string;
  planLevel: number | string;
  hasPremiumSupport: boolean;
  popular: boolean;
  hidden: boolean;
  isEnterprisePlan: boolean;
  isStarterPlan: boolean;
  isStarterFlexPlan: boolean;
  oldPlanId: string;
  interval: string; // 'year' | 'month'
  interval_count: number;
  one_time_fee_price_id: string;
  currency: CurrencyCode;
  originalPriceMonthly: Currency;
  price: Currency;
  displayOriginalPrice: Currency;
  translationKey: string;
  planName: string;
  strikethrough_price?: string | null;

  constructor(object: any = {}) {
    this.id = object?.id || '';
    this.title = object?.title || '';
    this.subtitle = object?.subtitle || '';
    this.codeLimit = object?.codeLimit || '';
    this.scanLimit = object?.scanLimit || '';
    this.product_id = object?.product_id || '';
    this.bulkLimit = object?.bulkLimit || null;
    this.apiLimit = object?.apiLimit || null;
    this.userLimit = object?.userLimit || '';
    this.planLevel = object?.planLevel || -1; // not subscribed - TODO: plan level enum
    this.hasPremiumSupport = !!object?.hasPremiumSupport || false;
    this.popular = !!object?.popular || false;
    this.hidden = !!object?.hidden || false;
    this.isEnterprisePlan = !!object?.isEnterprisePlan || false;
    this.oldPlanId = object?.oldPlanId || '';
    this.interval = object?.interval || '';
    this.interval_count = object?.interval_count || 1;
    this.one_time_fee_price_id = object?.one_time_fee_price_id || '';
    this.currency = object?.currency || '';
    this.isStarterPlan = (!!object?.planName && object?.planName === 'Starter') || false;
    this.isStarterFlexPlan = (!!object?.planName && object?.planName === 'Flex') || false;

    this.originalPriceMonthly = object?.originalPriceMonthly?.price
      ? object.originalPriceMonthly
      : new Currency(object?.originalPriceMonthly || 0);

    this.price = object?.price?.price ? object.price : new Currency(object?.price || 0);

    this.displayOriginalPrice = object?.displayOriginalPrice?.price
      ? object.displayOriginalPrice
      : new Currency(object?.displayOriginalPrice || 0);

    this.translationKey = object?.translationKey || '';
    this.planName = object?.planName || '';
    // Used for showing the discounted price on the checkout page summary section
    this.strikethrough_price = object?.strikethrough_price || null;
  }
}

export interface LoadingState {
  loadingSubscription: boolean;
  cancelSubscription: boolean;
  reactivateSubscription: boolean;
  loadingPlans: boolean;
}

export class Invoice {
  id: any = 0;
  amount_due: number = 0;
  amount_paid: number = 0;
  amount_remaining: number = 0;
  created: number = 0;
  currency: string = '';
  hosted_invoice_url: string = '';
  invoice_pdf: string = '';
  number: string = '';
  original_status: string = '';
  paid: boolean = true;
  plan: string = '';
  provider: string = '';
  status: string = '';

  constructor(object: any = {}) {
    this.id = object?.id || 0;
    this.amount_due = object?.amount_due || 0;
    this.amount_paid = object?.amount_paid || 0;
    this.amount_remaining = object?.amount_remaining || 0;
    this.created = object?.created || 0;
    this.currency = object?.currency || '';
    this.hosted_invoice_url = object?.hosted_invoice_url || '';
    this.invoice_pdf = object?.invoice_pdf || '';
    this.number = object?.number || '';
    this.original_status = object?.original_status || '';
    this.paid = !!object?.paid || true;
    this.plan = object?.plan || '';
    this.provider = object?.provider || '';
    this.status = object?.status || '';
  }
}

export class SubscriptionState {
  activeSubscription: Subscription = new Subscription();
  activePlan: Plan = new Plan();
  plans: Array<Plan> = [];
  selectedPlan: Plan = new Plan(); // Just a helper for plan selection NOT the active plan from subscription
  showLoading: LoadingState = {
    loadingSubscription: false,
    cancelSubscription: false,
    reactivateSubscription: false,
    loadingPlans: false,
  };
}
