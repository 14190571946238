<template>
  <div>
    <EmptyState v-if="subscriptionType === 'upgrade'" illustration="upgrade_illustration.png" :width="450">
      <h2 class="text-h2">
        {{ $t('fields.checkout.complete.upgrade_title') }}
      </h2>
      <p
        class="body-1 lapis--text text--lighten-2 mt-3"
        v-html="$t('fields.checkout.complete.upgrade_text', { planTitle: $t(`fields.${newPlan.translationKey}`) })"
      />

      <!-- Create button -->
      <v-btn depressed rounded :ripple="false" color="primary" x-large to="/create/" class="mt-10">
        {{ $t('fields.checkout.complete.upgrade_button') }}
        <Icon name="chevron-system" size="20" large right outline class="icon--transform" />
      </v-btn>
    </EmptyState>

    <EmptyState v-else-if="subscriptionType === 'downgrade'" illustration="downgrade_illustration.png" :width="450">
      <h2 class="text-h2">
        {{ $t('fields.checkout.complete.downgrade_title') }}
      </h2>
      <p
        class="body-1 lapis--text text--lighten-2 mt-3"
        v-html="
          $t('fields.checkout.complete.downgrade_text', {
            planTitle: $t(`fields.${newPlan.translationKey}`),
            endDate: activeSubscriptionEndDate(),
          })
        "
      />

      <!-- Create button -->
      <v-btn
        depressed
        rounded
        :ripple="false"
        color="primary"
        x-large
        class="mt-10"
        data-test="downgrade-got-it-button"
      >
        <!-- TODO Find out why the state is broken on the billing page when using the vuetify-button's to-attribute -->
        <a href="/my-account/billing" style="color: #fff; text-decoration: none">{{
          $t('fields.checkout.complete.downgrade_button')
        }}</a>
      </v-btn>
    </EmptyState>

    <EmptyState v-else illustration="upgrade_illustration.png" :width="450">
      <h2 class="text-h2">
        {{ $t('fields.checkout.complete.new_plan_title', { planTitle: $t(`fields.${newPlan.translationKey}`) }) }}
      </h2>
      <p class="body-1 lapis--text text--lighten-2 mt-3">
        {{ $t('fields.checkout.complete.new_plan_text') }}
      </p>

      <!-- Create button -->
      <v-btn depressed rounded :ripple="false" color="primary" x-large to="/create/" class="mt-10">
        {{ $t('fields.checkout.complete.new_plan_button') }}
        <Icon name="chevron-system" size="20" large right outline class="icon--transform" />
      </v-btn>
    </EmptyState>

    <TrackingScripts />
  </div>
</template>

<script lang="ts">
import AppConfig from '@/AppConfig';
import { Currencies } from '@/Models/Localization';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import { Plan, Subscription, SubscriptionState } from '@/classes/stripe';
import EmptyState from '@/components/EmptyState.vue';
import Icon from '@/components/Icons/Icon.vue';
import store from '@/store';
import axios from 'axios';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import TrackingScripts from './TrackingScripts.vue';

@Component({
  components: {
    TrackingScripts,
    Icon,
    EmptyState,
  },
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      subscriptionPlan: (state: SubscriptionState) => state.selectedPlan,
      activeSubscription: (state: SubscriptionState) => state.activeSubscription,
    }),
  },
})
export default class PaymentComplete extends Vue {
  @Prop({ default: 'new' })
  type!: string;

  // state from vuex
  activeSubscription!: Subscription;
  team = store.state.auth.team;

  // what type of subscription (downgrade or upgrade)
  subscriptionType = '';

  // selected subscription plan
  newPlan: Plan = new Plan();

  revenueInEUR: number = 0;

  apiBaseUrl: string = AppConfig.getAPIBaseUrl() || '';

  get subscriptionPlan(): Plan {
    return this.$store.state.subscription.selectedPlan;
  }

  get paymentIntentStatus(): string | undefined {
    return this.$route.query?.redirect_status as string;
  }

  // Setup the components needed data, like customer data.
  async created(): Promise<void> {
    let plan = this.subscriptionPlan;
    // Load plan if not already loaded and coming from a redirect
    let planName = this.$route.query?.plan as string;

    if (this.paymentIntentStatus && this.paymentIntentStatus !== 'succeeded') {
      await this.$router.push({
        name: 'checkout_payment_form_preselect_plan_name',
        params: { planName },
      });
      return;
    }

    if (this.$route.query?.plan && !plan.id) {
      const planByName = this.loadPlan(planName);
      if (planByName) {
        plan = planByName;
      }
    }
    // Without a plan a checkout is not possible so we redirect the customer to the selection page
    if (!plan.id) {
      await this.$router.push({ name: 'checkout_plan_selection' });
      return;
    }

    // Load plan from vuex
    Object.assign(this.newPlan, plan);

    this.subscriptionType = this.$router.currentRoute.params.type;
  }

  async mounted(): Promise<void> {
    if (this.paymentIntentStatus && this.paymentIntentStatus !== 'succeeded') {
      return;
    }

    await this.convertToEUR(
      this.$store.state.subscription.selectedPlan.price.price,
      this.$store.state.auth.team.currency
    );
    this.pushDataLayerToGtm();

    // Clear selectedPlan after checkout is completely done
    await this.$store.dispatch('subscription/resetSelectedPlan');
  }

  loadPlan(planName: string): Plan | undefined {
    let plan: Plan | undefined;
    if (planName) {
      const stripeSubscriptionService = new StripeSubscriptionService();
      plan = stripeSubscriptionService.getStandardPlanByName(planName);
    }
    return plan;
  }

  /**
   * Converts the given price (with currency) to EUR
   * @param price
   * @param currency
   */
  async convertToEUR(price: number, currency: string): Promise<void> {
    if (currency !== 'eur') {
      await axios
        .post(this.apiBaseUrl.concat('/currency/convert/'), {
          value: price,
          currency: currency,
          returnCurrency: Currencies.EUR.toUpperCase(),
        })
        .then((resp: any) => {
          if (resp.data) {
            this.revenueInEUR = resp.data.value;
          }
        })
        .catch((error) => console.error('Failed converting to EUR with the following error: ' + error));
    } else {
      this.revenueInEUR = price;
    }
  }

  activeSubscriptionEndDate(): string {
    return dayjs(this.activeSubscription.ends_at).format('MMMM D, YYYY');
  }

  pushDataLayerToGtm(): void {
    window.dataLayer = window.dataLayer || [];

    const user = this.$store.state.auth.user;
    const team = this.$store.state.auth.user?.team;
    const account = this.$store.state.account.account;
    const activeSubscription = this.$store.state.subscription.activeSubscription;
    const selectedPlan = this.$store.state.subscription.selectedPlan;

    window.dataLayer.push({
      event: 'checkout_complete',
      user: [
        {
          id: user?.id,
          email: user?.email,
          is_team_lead: user?.is_team_lead,
          created_at: team?.created_at,
          updated_at: team?.updated_at,
          first_name: team?.first_name,
          last_name: team?.last_name,
          region: team?.state,
          street: team.line2 ? `${team?.line1} ${team?.line2}` : team?.line1,
          postcode: team?.postal_code,
          city: team?.city,
          country: team?.country,
        },
      ],
      account: [
        {
          id: account?.id,
          trial: account?.trial,
          trial_date: account?.trial_date,
          type: account?.type,
          payment_open: account?.payment_open,
          payment_overdue: account?.payment_overdue,
          status: account?.status,
          payment_creditcard_expired: account?.payment_creditcard_expired,
          trial_left: account?.trial_left,
          qrcodes_current: account?.qrcodes?.current,
          qrcodes_allowed: account?.qrcodes?.allowed,
          industry_id: account?.industry_id,
          material_id: account?.material_id,
        },
      ],
      statistics: [
        {
          active: account?.statistics?.active,
          paused: account?.statistics?.paused,
          all: account?.statistics?.all,
          expired: account?.statistics?.expired,
          scans: account?.statistics?.scans,
          designs: account?.statistics?.designs,
        },
      ],
      active_subscription: [
        {
          id: activeSubscription?.id,
          created_at: activeSubscription?.created_at?.date,
          updated_at: activeSubscription?.updated_at?.date,
          plan_id: activeSubscription?.plan_id,
          new_plan_id: activeSubscription?.new_plan_id,
          is_on_invoice: activeSubscription?.is_on_invoice,
          is_cancelled: activeSubscription?.is_cancelled,
          is_active: activeSubscription?.is_active,
          ends_at: activeSubscription?.ends_at,
        },
      ],
      selected_plan: [
        {
          oldPlanId: selectedPlan?.oldPlanId,
          id: selectedPlan?.id,
          title: selectedPlan?.title,
          subtitle: selectedPlan?.subtitle,
          code_limit: selectedPlan?.codeLimit,
          scan_limit: selectedPlan?.scanLimit,
          bulk_limit: selectedPlan?.bulkLimit,
          api_limit: selectedPlan?.apiLimit,
          user_limit: selectedPlan?.userLimit,
          premium_support: selectedPlan?.hasPremiumSupport,
          price: selectedPlan?.price?.price,
          price_currency: selectedPlan?.price?.currency,
          price_locale: selectedPlan?.price?.locale,
          price_symbol: selectedPlan?.price?.symbol,
          price_converted_eur: this.revenueInEUR,
        },
      ],
    });
  }
}
</script>
