import { render, staticRenderFns } from "./OrderSummary.vue?vue&type=template&id=2341d70b"
import script from "./OrderSummary.vue?vue&type=script&lang=ts"
export * from "./OrderSummary.vue?vue&type=script&lang=ts"
import style0 from "./OrderSummary.vue?vue&type=style&index=0&id=2341d70b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports