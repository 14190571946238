import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import Country from '@/Models/Static/Country';
import countries from '@/Models/Static/countries.json';
import Authenticator from '@/Services/Auth/Authenticator';
import StripeService from '@/Services/Subscription/StripeService';
import { Plan } from '@/classes/stripe';
import store from '@/store';
import { LocationState } from '@/store/location';
import * as Sentry from '@sentry/vue';

/**
 * StripeCheckoutService is responsible for handling the checkout procedure,
 * calling different service classes if needed to perform a solid checkout process.
 */
export default class StripeCheckoutService {
  customer = {
    email: '',
    name: '',
    metadata: {
      first_name: '',
      last_name: '',
    },
    tax_id_data: {
      type: 'eu_vat',
      value: '',
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
    },
    invoice_settings: {
      custom_fields: [
        {
          value: '',
        },
      ],
    },
  };

  // Which payment period is displayed
  paymentPeriod = 12;

  // Is customer b2b or b2c?
  legalEntity: string = 'b2b';

  // List of selectable countries for e.g. the payment form
  countries: Array<Country> = countries;

  // From which country is the customer?
  get location(): LocationState {
    return store.state.location;
  }

  // Is selected country part of the eu with a usable vat rate.
  get isEuCountry(): boolean {
    return store.getters['location/isEu'];
  }
  initCustomerData(): void {
    const team = Authenticator.getTeam();

    if (team) {
      this.customer.email = team.email;
      this.customer.name = team.legal_entity === 'b2b' ? team.company : '';
      this.customer.metadata.first_name = team.first_name ?? '';
      this.customer.metadata.last_name = team.last_name ?? '';
      this.customer.tax_id_data.value = team.vat_id ?? '';
      this.customer.address.line1 = team.line1;
      this.customer.address.line2 = team.line2;
      this.customer.address.city = team.city;
      this.customer.address.country = team.country;
      this.customer.address.state = team.state;
      this.customer.address.postal_code = team.postal_code;
    }
  }

  /**
   * On manual update via link we update country in address for
   * customer as well as the tax_id_data.type to eu_vat if selected
   * country is inside the EU.
   */
  async selectCountry(countryIso2: string): Promise<void> {
    await store.dispatch('location/setCountryManualByIso2', countryIso2);
    await this.updateCustomerVatRate();
    return Promise.resolve();
  }

  // Calculate for a given net price the gross price difference for n months => tax to pay
  calcVatRateTax(plan: Plan, vatRate: number, months = 1, prorate: number): Currency {
    const tmp = new Currency();
    if (vatRate !== null) {
      tmp.price = (plan.price.price * months - -prorate) * (vatRate / 100);
    }
    return tmp;
  }

  // Calculate net price for n months
  calcNetPayment(plan: Plan, months = 1, prorate = 0): Currency {
    const tmp = new Currency();
    tmp.price = plan.price.price * months - -prorate;
    return tmp;
  }

  // Calculate gross price for n months
  calcGrossPayment(plan: Plan, vatRate: number, months = 1, prorate = 0): Currency {
    const tmp = new Currency();
    tmp.price =
      this.calcVatRateTax(plan, vatRate, months, prorate).price + this.calcNetPayment(plan, months, prorate).price;
    return tmp;
  }
  /**
   * Detect new vat rate and adjust customer values accordingly.
   */
  async updateCustomerVatRate(): Promise<void> {
    await store.dispatch('location/detectVatRate', { customer: this.customer, legalEntity: this.legalEntity });
    this.customer.tax_id_data.type = this.isEuCountry ? StripeService.VAT_EU : '';

    if (this.customer.address.country === '') {
      this.customer.address.country = this.location.country_iso2 ?? null;
    }

    return Promise.resolve();
  }

  isEU(country: string): boolean {
    const EUCountries = countries.filter((item) => item.continent_iso2 === StripeService.CONTINENT_EU);
    return !!EUCountries.find((item) => item.country_iso2 === country);
  }

  isVatIdNeeded(country: string): boolean {
    try {
      return this.isEU(country) && !StripeService.countriesWithoutVatIdFieldISO2.includes(country);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtra('country', country);
        scope.setExtra('method', 'isVatIdNeeded');
        Sentry.captureMessage('[error] dbip fail');
      });
      return false;
    }
  }
}
