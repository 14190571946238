import { loadStripe, Stripe } from '@stripe/stripe-js';
import AppConfig from '@/AppConfig';

/**
 * StripeService is the base class for stripe resource services.
 */
export default class StripeService {
  // Official stripe wrapper for stripe-js sdk
  stripe!: Stripe;

  apiVersion = '2024-06-20';

  static CONTINENT_EU = 'EU';
  static COUNTRY_DE = 'DE';
  static COUNTRY_RU = 'RU';
  static COUNTRY_CH = 'CH';
  static COUNTRY_IS = 'IS';
  static COUNTRY_NO = 'NO';
  static COUNTRY_RS = 'RS';
  static countriesWithoutVatIdFieldISO2: string[] = [
    StripeService.COUNTRY_IS,
    StripeService.COUNTRY_DE,
    StripeService.COUNTRY_NO,
    StripeService.COUNTRY_RS,
    StripeService.COUNTRY_RU,
    StripeService.COUNTRY_CH,
  ];
  static VAT_EU = 'eu_vat';

  constructor() {}

  /**
   * Load stripe instance, we need this to use stripe elements
   * which provides the payment input fields for e.g. credit card
   */
  async initStripe(stripeKey: string = AppConfig.getDefaultStripeKey()): Promise<Stripe | null> {
    if (!stripeKey) {
      throw new Error('Stripe key is missing');
    }
    if (this.stripe) {
      return this.stripe;
    }

    await loadStripe(stripeKey ?? '', { apiVersion: this.apiVersion }).then((instance: Stripe | null) => {
      if (instance) {
        this.stripe = instance;
      }
    });
    return this.stripe ?? null;
  }
}
