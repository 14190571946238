import { Commit } from 'vuex';
import axios, { AxiosError } from 'axios';
import AppConfig from '@/AppConfig';
import { Account } from '@/classes/account';
import * as Sentry from '@sentry/vue';
import { Invoice } from '@/classes/stripe';

export interface AccountState {
  loading: boolean;
  account: Account | null;
  invoices?: Array<Invoice>;
}

const AccountStore = {
  namespaced: true,
  state: {
    loading: false,
    account: new Account(),
    invoices: [],
  },
  mutations: {
    setAccount(state: AccountState, account: Account | null): void {
      state.account = new Account(account);
    },
    setInvoices(state: AccountState, invoices?: Array<Invoice>): void {
      state.invoices = invoices;
    },
    setLoading(state: AccountState, loading: boolean): void {
      state.loading = loading;
    },
  },
  actions: {
    async loadAccount({ commit }: { commit: Commit }): Promise<any> {
      commit('setLoading', true);

      axios
        .get(`${AppConfig.getAPIBaseUrl()}/account`)
        .then((response) => {
          return commit('setAccount', response.data);
        })
        .catch((error: Error) => {
          console.error('Error fetching account data from Checkout API: ' + error);
          return commit('setAccount', {});
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    async loadInvoices({ commit }: { commit: Commit }): Promise<any> {
      commit('setLoading', true);
      await axios
        .get(AppConfig.getAPIBaseUrl() + '/subscription/stripe/invoices/')
        .then((response) => {
          const invoices =
            response?.data?.data.map((invoice: any) => {
              if (!invoice.paid && !invoice.number) {
                invoice.status = 'pending';
              }
              return new Invoice(invoice);
            }) || [];
          commit('setInvoices', invoices);
        })
        .catch((error: AxiosError) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },
};

export default AccountStore;
