<template>
  <div class="pricing-plan__card-wrapper">
    <v-row class="mb-sm-0 mb-lg-8 mb-md-8 mb-xl-8">
      <v-col lg="10" offset-lg="1" class="py-0">
        <v-row align="center" justify="center">
          <v-col lg="12" md="12" xl="9" sm="12" cols="12">
            <v-card>
              <v-card-text class="pb-2 pt-sm-0">
                <v-row align="center" justify="center">
                  <v-col sm="6" md="4" lg="4" xl="3" cols="12" class="pb-sm-4 pb-0">
                    <div class="pricing-plan__title-wrapper">
                      <!-- Plan title -->
                      <v-card-title class="pricing-plan__title">
                        <h3 class="text-h3 lapis--text">
                          {{ $t(`fields.${plan.translationKey}`) }}
                        </h3>
                      </v-card-title>
                      <!-- Plan title END -->

                      <!-- Plan subtitle -->
                      <v-card-subtitle class="pricing-plan__subtitle">
                        <p class="subtitle-2">
                          {{ $t(plan.subtitle) }}
                        </p>
                      </v-card-subtitle>
                      <!-- Plan subtitle END -->
                    </div>

                    <!-- Plan price -->
                    <v-row>
                      <v-col class="pricing-plan__price-wrapper">
                        <span class="pricing-plan__currency">
                          {{ returnSymbol }}
                        </span>
                        <span class="pricing-plan__price">
                          {{ returnDisplayPrice }}
                        </span>
                        <span :class="plan.strikethrough_price ? 'pricing-plan__strikethrough_price-container' : ''">
                          <span v-if="plan.strikethrough_price" class="pricing-plan__strikethrough_price">
                            {{ returnSymbol }} {{ returnStrikethroughPriceMonthly }}
                          </span>
                          <span class="pricing-plan__billing">
                            /{{ $t('fields.pricing_table.plan_price_per_month') }}
                          </span>
                        </span>
                      </v-col>
                    </v-row>
                    <!-- Plan price END -->

                    <!-- Plan billing -->
                    <v-row>
                      <v-col class="pricing-plan__billing-info">
                        {{ $t('fields.pricing_table.quarterly_charge', { amount: totalDisplayPrice }) }}
                      </v-col>
                    </v-row>
                    <!-- Plan billing END -->
                  </v-col>

                  <v-col
                    md="4"
                    lg="4"
                    xl="3"
                    class="order-lg-2 order-md-2 order-xl-2 order-sm-1 pt-0 pricing-plan__select-btn--desktop"
                  >
                    <!-- Select button desktop-->
                    <v-row align="center" justify="center">
                      <v-col align="center" justify="center" class="pt-4 pb-0">
                        <v-btn
                          depressed
                          rounded
                          :ripple="false"
                          large
                          :outlined="subscribeBtnState === 'downgrade'"
                          :color="subscribeBtnState === 'downgrade' ? 'neutral darken-3' : 'primary'"
                          :loading="loading"
                          :disabled="subscribeBtnState === 'selected' || loading"
                          :data-test="'plan_' + plan.planName + '_' + subscribeBtnState"
                          @click="planSelected(plan, subscribeBtnState)"
                        >
                          {{ subscribeBtnLabel }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Select button desktop END -->
                  </v-col>

                  <v-col sm="6" md="4" lg="4" xl="3" cols="12" class="order-sm-2 order-lg-1 order-md-1 order-xl-1">
                    <v-simple-table class="pricing-plan__feature-table">
                      <template #default>
                        <tbody>
                          <template v-for="feature in featureList">
                            <tr v-if="feature.value !== null && feature.value !== '0'" :key="feature.title">
                              <td class="text-center pricing-plan__feature-amount-wrapper">
                                <div class="pricing-plan__feature-amount">
                                  <template v-if="feature.value === '-1'">
                                    {{ $t('fields.pricing_table.feature_list.unlimited_value').toString() }}
                                  </template>
                                  <template v-else-if="feature.title === 'Scans' && isUserOnAbtestVariantCNV3020()">
                                    Unlimited
                                  </template>
                                  <template v-else>
                                    {{ feature.value }}
                                  </template>
                                </div>
                              </td>
                              <td>
                                <v-tooltip top transition="none" max-width="320">
                                  <template #activator="{ on }">
                                    <v-btn
                                      text
                                      :ripple="false"
                                      color="primary"
                                      small
                                      class="v-btn--text--link--dashed"
                                      v-on="on"
                                    >
                                      <span>
                                        {{ feature.title }}
                                      </span>
                                    </v-btn>
                                  </template>
                                  <span v-html="feature.tooltip" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <!-- Select button mobile -->
                <v-row align="center" justify="center" class="pricing-plan__select-btn--mobile">
                  <hr class="pricing-plan__fullwidth-border pricing-plan__fullwidth-border--mobile" />
                  <v-col cols="12" align="center" justify="center" class="pt-4 pb-2">
                    <v-btn
                      :id="plan.id"
                      depressed
                      rounded
                      :ripple="false"
                      large
                      :outlined="subscribeBtnState === 'downgrade'"
                      :color="subscribeBtnState === 'downgrade' ? 'neutral darken-3' : 'primary'"
                      :loading="loading"
                      :disabled="subscribeBtnState === 'selected' || loading"
                      :data-test="'plan_' + plan.planName + '_' + subscribeBtnState"
                      @click="planSelected(plan, subscribeBtnState)"
                    >
                      {{ subscribeBtnLabel }}
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- Select button mobile END -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import BasePlanTile from './PlanTile.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    BasePlanTile,
  },
  computed: {},
})
export default class HeaderPlanTile extends BasePlanTile {}
</script>

<style lang="scss"></style>
