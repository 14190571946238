<template>
  <div
    :class="[
      'pricing-plan__card-wrapper',
      {
        'pricing-plan__card-wrapper--disabled': disablePlanTile,
      },
    ]"
  >
    <div v-if="showMostPopular" class="pricing-plan__most-popular">
      {{ $t('fields.pricing_table.plan_popular') }}
    </div>

    <div
      v-if="showBestValue"
      :class="[
        'pricing-plan__best-value',
        {
          'pricing-plan__best-value--flex': isUserOnFlexplanVariantCorD(),
        },
      ]"
    >
      {{
        $t('fields.pricing_table.plan_best_value', {
          savings_percentage: bestValueSavings,
        })
      }}
    </div>

    <v-card
      outlined
      height="100%"
      class="pricing-plan__card"
      :class="[
        {
          'pricing-plan__card--most-popular': showMostPopular,
          'pricing-plan__card--best-value': showBestValue,
          'pricing-plan__card--best-value--flex': showBestValue && isUserOnFlexplanVariantCorD(),
        },
      ]"
    >
      <v-card-text class="pb-2 pt-sm-0 h-100">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            :md="isUserOnFlexplanVariantCorD() ? 6 : 12"
            lg="12"
            :class="{ 'pt-5': isUserOnFlexplanVariantCorD() }"
          >
            <div class="pricing-plan__title-wrapper">
              <div v-if="plan.isStarterFlexPlan" class="pricing-plan__flex-header d-none d-sm-block">
                {{ $t('fields.pricing_table.need_more_flexibility') }}
              </div>

              <!-- Plan title -->
              <v-card-title
                :class="[
                  'pricing-plan__title',
                  {
                    'pricing-plan__title--flexplan': plan.isStarterFlexPlan,
                  },
                ]"
              >
                <h3 class="text-h3 lapis--text">
                  {{ $t(`fields.${plan.translationKey}`) }}
                </h3>
              </v-card-title>
              <!-- Plan title END -->

              <!-- Plan subtitle -->
              <v-card-subtitle class="pricing-plan__subtitle">
                <p class="subtitle-2">
                  {{ $t(plan.subtitle) }}
                </p>
              </v-card-subtitle>
              <!-- Plan subtitle END -->
            </div>

            <!-- Plan price -->
            <v-row>
              <v-col xs="12" class="pricing-plan__price-wrapper">
                <span class="pricing-plan__currency">
                  {{ returnSymbol }}
                </span>
                <span class="pricing-plan__price">
                  {{ returnDisplayPrice }}
                </span>
                <span :class="plan.strikethrough_price ? 'pricing-plan__strikethrough_price-container' : ''">
                  <span v-if="plan.strikethrough_price" class="pricing-plan__strikethrough_price">
                    {{ returnSymbol }} {{ returnStrikethroughPriceMonthly }}
                  </span>
                  <span class="pricing-plan__billing"> /{{ $t('fields.pricing_table.plan_price_per_month') }} </span>
                </span>
              </v-col>
            </v-row>
            <!-- Plan price END -->

            <!-- Plan billing -->
            <v-row>
              <v-col xs="12" class="pricing-plan__billing-info">
                {{
                  $t(getBillingInfoForPlan(plan), {
                    amount: totalDisplayPrice,
                  })
                }}
              </v-col>
            </v-row>
            <!-- Plan billing END -->

            <!-- Select button desktop-->
            <v-row
              align="center"
              justify="center"
              :class="
                isUserOnFlexplanVariantCorD() ? 'd-flex d-sm-none d-lg-flex' : 'pricing-plan__select-btn--desktop'
              "
            >
              <v-col xs="12" md="8" lg="8" align="center" justify="center" class="pt-4 pb-0">
                <v-btn
                  depressed
                  rounded
                  :ripple="false"
                  large
                  :outlined="subscribeBtnState === 'downgrade'"
                  :color="subscribeBtnState === 'downgrade' ? 'neutral darken-3' : 'primary'"
                  :loading="loading"
                  :disabled="subscribeBtnState === 'selected' || loading || disablePlanTile"
                  :data-test="'plan_' + plan.planName + '_' + subscribeBtnState"
                  @click="planSelected(plan, subscribeBtnState)"
                >
                  <span class="d-none d-sm-block">
                    {{ subscribeBtnLabelAbTest(plan) }}
                  </span>
                  <span class="d-block d-sm-none">
                    {{ subscribeBtnLabel }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Select button desktop END -->
          </v-col>

          <v-col cols="12" sm="6" :md="isUserOnFlexplanVariantCorD() ? 6 : 12" lg="12" class="px-4">
            <!-- Plan features -->
            <v-simple-table class="pricing-plan__feature-table">
              <template #default>
                <tbody>
                  <template v-for="feature in featureList">
                    <tr v-if="feature.value !== null && feature.value !== '0'" :key="feature.title">
                      <td class="text-center pricing-plan__feature-amount-wrapper">
                        <div class="pricing-plan__feature-amount">
                          <template v-if="feature.value === '-1'">
                            {{ $t('fields.pricing_table.feature_list.unlimited_value').toString() }}
                          </template>
                          <template v-else-if="feature.title === 'Scans' && isUserOnAbtestVariantCNV3020()">
                            Unlimited
                          </template>
                          <template v-else>
                            {{ feature.value }}
                          </template>
                        </div>
                      </td>
                      <td>
                        <v-tooltip top transition="none" max-width="320">
                          <template #activator="{ on }">
                            <v-btn
                              text
                              :ripple="false"
                              color="primary"
                              small
                              class="v-btn--text--link--dashed"
                              v-on="on"
                            >
                              <span>
                                {{ feature.title }}
                              </span>
                            </v-btn>
                          </template>
                          <span v-html="feature.tooltip" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-if="plan.isStarterPlan && isUserOnFlexplanVariantCorD() && disablePlanTile"
                    class="d-none d-sm-table-row d-lg-none"
                  >
                    <td colspan="2">
                      <div class="pricing-plan__best-value-tag text-center mt-3 ml-5">
                        {{
                          $t('fields.pricing_table.plan_best_value', {
                            savings_percentage: bestValueSavings,
                          })
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- Plan features END -->
          </v-col>
        </v-row>

        <!-- Plan support -->
        <template v-if="plan.hasPremiumSupport">
          <v-row>
            <v-col class="text-center pa-0 mt-7 mt-sm-0">
              <hr class="pricing-plan__fullwidth-border" />
              <span class="pricing-plan__premium-support">
                {{ $t('fields.pricing_table.premium_support') }}
              </span>
            </v-col>
          </v-row>
        </template>
        <!-- Plan support END -->

        <template v-if="plan.isStarterPlan && isUserOnFlexplanVariantCorD() && disablePlanTile">
          <v-row class="d-none d-lg-flex mt-3">
            <v-col cols="12" class="pa-0">
              <div class="pricing-plan__best-value-tag mt-14 text-center">
                {{
                  $t('fields.pricing_table.plan_best_value', {
                    savings_percentage: bestValueSavings,
                  })
                }}
              </div>
            </v-col>
          </v-row>
        </template>

        <v-row
          align="center"
          justify="center"
          :class="isUserOnFlexplanVariantCorD() ? 'd-none d-sm-flex d-lg-none' : 'pricing-plan__select-btn--mobile'"
        >
          <hr
            :class="
              isUserOnFlexplanVariantCorD()
                ? 'pricing-plan__fullwidth-border d-none d-sm-flex d-lg-none'
                : 'pricing-plan__fullwidth-border pricing-plan__fullwidth-border--mobile'
            "
          />
          <v-col cols="12" align="center" justify="center" class="pt-4 pb-2">
            <v-btn
              :id="plan.id"
              depressed
              rounded
              :ripple="false"
              large
              :outlined="subscribeBtnState === 'downgrade'"
              :color="subscribeBtnState === 'downgrade' ? 'neutral darken-3' : 'primary'"
              :loading="loading"
              :disabled="subscribeBtnState === 'selected' || loading || disablePlanTile"
              :data-test="'plan_' + plan.planName + '_' + subscribeBtnState"
              @click="planSelected(plan, subscribeBtnState)"
            >
              <span class="d-none d-sm-block">
                {{ subscribeBtnLabelAbTest(plan) }}
              </span>
              <span class="d-block d-sm-none">
                {{ subscribeBtnLabel }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Select button desktop END -->

        <downgrade-restricted-modal
          :open="openDowngradeModal"
          :new-plan="plan"
          :user-code-amount="qrcodes.current"
          @cancel="onCancel"
        />
      </v-card-text>
    </v-card>
    <!-- Reactivate subscription -->
    <reactivate-subscription
      :open="reactivateModalShow"
      :renew="activeSubscription.current_period_end.format('MMM DD, YYYY')"
      @reactivate="onReactivate"
      @cancel="onCancel"
    />
  </div>
</template>

<script lang="ts">
import { Currencies, CurrencyCode, CurrencySymbols, Locales } from '@/Models/Localization';
import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import KeyValueItem from '@/Models/PrimitiveModels/KeyValueItem';
import { trackCheckoutNowButtonPricingPageStep1 } from '@/Services/GoogleTagManager';
import { trackCheckoutNowButtonPricingPage } from '@/Services/Snowplow/SnowplowService';
import { labelForPlanState } from '@/Services/Subscription/PlanViewService';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import { Team } from '@/classes/auth';
import { Plan, Subscription } from '@/classes/stripe';
import DowngradeRestrictedModal from '@/components/Modals/Account/DowngradeRestrictedModal.vue';
import ReactivateSubscription from '@/components/Modals/Account/ReactivateSubscription.vue';
import store from '@/store';
import { AccountState } from '@/store/account';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import OptimizelyService from '@/Services/OptimizelyService';

interface ExchangeRateInterface {
  key: string;
  value: { exchangeRate: number; locale: string; symbol: string };
}

@Component({
  components: {
    DowngradeRestrictedModal,
    ReactivateSubscription,
  },
  computed: {
    ...mapState<AccountState>('account', {
      id: (state: AccountState) => state.account?.id || [],
      qrcodes: (state: AccountState) => state.account?.qrcodes || [],
    }),
    ...mapGetters('auth', {
      team: 'team',
    }),
  },
})
export default class PlanTile extends Vue {
  @Prop({ default: () => new Plan() })
  plan!: Plan;

  @Prop({ default: () => new StripeSubscriptionService() })
  stripeSubscriptionService!: StripeSubscriptionService;

  @Prop({ default: Currencies.EUR })
  currencyIso2!: string;

  @Prop({ default: 'month' })
  selectedInterval!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: 0 })
  bestValueSavings!: number;

  @Prop({ default: false })
  disablePlanTile!: boolean;

  get activeSubscription(): Subscription {
    return store.state.subscription.activeSubscription;
  }

  getBillingInfoTranslationKey(): string {
    return 'fields.pricing_table.plan_billing_info';
  }

  getBillingInfoForPlan(plan: Plan): string {
    if (!OptimizelyService.isFlexPlanABTestEnabled(store.state?.auth?.user)) {
      return this.getBillingInfoTranslationKey();
    }

    return plan.isStarterFlexPlan ? 'fields.pricing_table.quarterly_charge' : 'fields.pricing_table.annual_charge';
  }

  team!: Team;

  openDowngradeModal = false;
  qrcodes: any;

  reactivateModalShow = false;
  initialCurrency: CurrencyCode = '';
  initialPlanPrice: number = 0;

  /**
   * All exchange-rate values are based on 1EUR so we can easily multiply
   * to get new value.
   */
  exchangeRates: Array<KeyValueItem> = [
    {
      key: Currencies.USD,
      value: {
        exchangeRate: 1.041879, // 1EUR => USD
        locale: Locales.en_US,
        symbol: CurrencySymbols.USD,
      },
    },
    {
      key: Currencies.GBP,
      value: {
        exchangeRate: 0.875723, // 1EUR => GBP
        locale: Locales.en_GB,
        symbol: CurrencySymbols.GBP,
      },
    },
    {
      key: Currencies.BRL,
      value: {
        exchangeRate: 6.15, // 1EUR => BRL
        locale: Locales.pt_BR,
        symbol: CurrencySymbols.BRL,
      },
    },
  ];

  // Limits of this plan as a clean list, null values won't be shown
  featureList: Array<{ title: string; value: string | null; tooltip: string }> = [];

  mounted(): void {
    this.subscribeButtonLabel();

    this.initialCurrency = this.currencyIso2 as CurrencyCode;
    this.initialPlanPrice = this.plan.price.price;

    this.featureList = [
      {
        title: this.$t('fields.pricing_table.feature_list.dynamic_codes_title').toString(),
        value: this.plan.codeLimit,
        tooltip: this.$t('fields.pricing_table.feature_list.dynamic_codes_description').toString(),
      },
      {
        title: this.$t('fields.pricing_table.feature_list.scans_title').toString(),
        value: this.plan.scanLimit,
        tooltip: this.$t('fields.pricing_table.feature_list.scans_description').toString(),
      },
      {
        title: this.$t('fields.pricing_table.feature_list.bulk_title').toString(),
        value: this.plan.bulkLimit,
        tooltip: this.$t('fields.pricing_table.feature_list.bulk_description').toString(),
      },
      {
        title: this.$t('fields.pricing_table.feature_list.api_requests_title').toString(),
        value: this.plan.apiLimit,
        tooltip: this.$t('fields.pricing_table.feature_list.api_requests_description').toString(),
      },
      {
        title: this.$t('fields.pricing_table.feature_list.users_title').toString(),
        value: this.plan.userLimit,
        tooltip: this.$t('fields.pricing_table.feature_list.users_description').toString(),
      },
    ];
  }

  get totalDisplayPrice(): string {
    let totalPrice = this.plan.price.priceToInt();

    if (this.returnExchangeRate?.value?.exchangeRate) {
      totalPrice = this.plan.price.priceToInt() * this.returnExchangeRate.value.exchangeRate;
    }
    const priceWithCurrency = new Currency(totalPrice);

    return `${this.returnSymbol} ${priceWithCurrency.priceAsValue({
      price: totalPrice,
      overrideCurrency: this.overrideCurrency,
    })}`;
  }

  get subscribeBtnLabel(): string {
    return this.subscribeButtonLabel().subscribeBtnLabel;
  }
  subscribeBtnLabelAbTest(plan: Plan): string {
    const planState = this.stripeSubscriptionService.planState(this.plan.id);
    const { label } = labelForPlanState(planState);

    if (planState === 'NO_PLAN' && !plan.isStarterFlexPlan && this.disablePlanTile) {
      return this.$t('fields.pricing_table.annual_plan').toString();
    } else if (planState === 'NO_PLAN' && plan.isStarterFlexPlan && this.disablePlanTile) {
      return this.$t('fields.pricing_table.three_month_plan').toString();
    }

    return String(this.$t(label));
  }

  get subscribeBtnState(): string {
    return this.subscribeButtonLabel().subscribeBtnState;
  }

  get overrideCurrency(): string {
    let overrideCurrency = '';
    if (this.returnExchangeRate?.key) {
      overrideCurrency = this.returnExchangeRate.key;
    }
    return overrideCurrency;
  }

  get returnExchangeRate(): ExchangeRateInterface | null {
    const exchangeRate = this.exchangeRates.find((v) => v.key == this.currencyIso2);
    if (exchangeRate) {
      return exchangeRate as ExchangeRateInterface;
    }
    return null;
  }

  get returnDisplayPrice(): string {
    if (this.currencyIso2 === this.initialCurrency) {
      this.plan.displayOriginalPrice = new Currency(this.initialPlanPrice);
    } else if (this.returnExchangeRate?.value?.exchangeRate) {
      this.plan.displayOriginalPrice = new Currency(this.initialPlanPrice * this.returnExchangeRate.value.exchangeRate);
    }

    return this.plan.displayOriginalPrice.displayPriceMonthly({
      plan: this.plan,
      selectedInterval: this.selectedInterval,
      overrideCurrency: this.overrideCurrency,
    });
  }

  get showExcludedVatTaxTooltip(): boolean {
    const currenciesWithVatTooltip = ['$', '€', '£'];
    return currenciesWithVatTooltip.includes(this.returnSymbol);
  }

  get returnExcludedVatTaxLabel(): string {
    return this.$t(
      `fields.pricing_table.excluded_vat_tooltip.${this.returnSymbol === '$' ? 'excluded_tax' : 'excluded_vat'}`
    ).toString();
  }

  get returnYearlyCostWithCurrency(): string {
    const price = this.plan.price.price;
    switch (this.returnSymbol) {
      case '$':
      case 'R$':
      case '£': {
        return `${this.returnSymbol} ${price}`;
      }
      default:
        return `${price} ${this.returnSymbol}`;
    }
  }

  get returnSymbol(): string {
    let overrideCurrency: string | CurrencyCode = this.plan.currency;
    if (this.returnExchangeRate?.key) {
      overrideCurrency = this.returnExchangeRate.key;
    }
    return this.plan.price.displaySymbol({ overrideCurrency });
  }

  get returnStrikethroughPriceMonthly(): string | undefined {
    if (this.plan.strikethrough_price) {
      return (parseFloat(this.plan.strikethrough_price) / 12).toFixed(2);
    }
    return undefined;
  }

  /**
   * Return the original price of a plan.
   */
  get originalPlanPrice(): number | undefined {
    return this.plan?.originalPriceMonthly?.price || undefined;
  }

  get showMostPopular(): boolean {
    return this.plan.popular && !OptimizelyService.isFlexPlanABTestEnabled(store.state?.auth?.user);
  }

  get showBestValue(): boolean {
    return (
      (OptimizelyService.isFlexPlanAbTestVariant1Enabled(store.state?.auth?.user) && this.plan.isStarterPlan) ||
      (this.isUserOnFlexplanVariantCorD() && this.plan.isStarterPlan)
    );
  }

  /**
   * Sets the button label according to which plan the user currently has
   */
  subscribeButtonLabel(): { subscribeBtnLabel: string; subscribeBtnState: string } {
    const planState = this.stripeSubscriptionService.planState(this.plan.id);
    const { label, state } = labelForPlanState(planState);

    return { subscribeBtnLabel: String(this.$t(label)), subscribeBtnState: state };
  }

  planSelected(plan: Plan, state: string | 'continue' | 'reactivate'): void {
    if (state === 'continue') {
      const language = store.state?.auth?.user?.language;
      const user_id = store.state?.auth?.user?.id;
      const account = store.state?.account?.account;
      const price_currency_displayed = store.state?.auth?.team?.currency;

      trackCheckoutNowButtonPricingPage({
        industry_id: account?.industry_id ?? null,
        user_id,
        price_currency_displayed,
        language_code: language,
        plan_id: plan.id,
      });
      trackCheckoutNowButtonPricingPageStep1(window.dataLayer, plan.planName, plan.price.price);
    }

    if (state === 'reactivate') {
      this.reactivateModalShow = true;
    } else if (!this.stripeSubscriptionService.canDowngradePlan(plan.id)) {
      this.showDowngradeFailModal();
    } else {
      // choose selected plan
      this.$store.dispatch('subscription/setSubscriptionPlan', plan).then(() => {
        this.$router.push({ name: 'checkout_payment_form', params: { planId: plan.id } });
      });
    }
  }

  showDowngradeFailModal(): void {
    this.openDowngradeModal = true;
  }

  async onReactivate(): Promise<void> {
    await this.stripeSubscriptionService.initSubscription();
    this.onCancel();
  }

  onCancel(): void {
    this.reactivateModalShow = false;
    this.openDowngradeModal = false;
  }

  isUserOnAbtestVariantCNV3020(): boolean {
    return OptimizelyService.isUserOnAbtestVariantCNV3020();
  }

  isUserOnFlexplanVariantCorD(): boolean {
    return OptimizelyService.isFlexPlanAbTestVariantCorD(store.state?.auth?.user);
  }
}
</script>

<style lang="scss">
.pricing-plan {
  &__card {
    border-width: 2px !important;

    &-wrapper {
      height: 100%;
      position: relative;

      @media (max-width: 959px) {
        padding-top: 24px;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &__card--most-popular,
  &__card--best-value {
    margin-top: -30px;
    border-color: map-get($turquoise, 'darken-1') !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;

    &--flex {
      @media (min-width: 599px) {
        border-top-right-radius: 12px !important;
        border-top-left-radius: 12px !important;
        border: 2px solid #e6edf0 !important;
        margin-top: 0;
      }
    }
  }

  &__card--best-value {
    color: map-get($neutral, 'darken-4') !important;
  }

  &__best-value-tag {
    background-color: map-get($turquoise, 'lighten-5');
    color: map-get($lapis, 'base');
    font-weight: bold;
    background: map-get($turquoise, 'darken-1');
    border: 2px solid map-get($turquoise, 'darken-1');
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
  }

  &__most-popular,
  &__best-value {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -28px;
    background: map-get($turquoise, 'darken-1');
    border: 2px solid map-get($turquoise, 'darken-1');
    text-align: center;
    font-weight: map-get($font-weights, 'bold');
    text-transform: uppercase;
    height: 32px;
    font-size: 12px;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;

    &--flex {
      @media (min-width: 599px) {
        display: none;
      }
    }
  }

  &__most-popular {
    color: map-get($neutral, 'lighten-4');
  }

  &__best-value {
    color: map-get($lapis, 'base');
  }

  &__title {
    text-align: center;
    justify-content: center;

    @media (min-width: 600px) and (max-width: 959px) {
      padding-top: 0;
    }

    &--flexplan {
      padding: 0 16px 10px;
    }
  }

  &__subtitle {
    text-align: center;
    padding-bottom: 0;

    .subtitle-2 {
      color: map-get($lapis, 'lighten-2');
      font-weight: map-get($font-weights, 'regular');
    }
  }

  &__price-wrapper {
    text-align: center;
    padding-bottom: 0;
  }

  &__billing {
    position: relative;
    font-size: 12px;
    color: map-get($lapis, 'lighten-2');
    font-weight: map-get($font-weights, 'regular');
  }

  &__currency {
    position: relative;
    font-size: 18px;
    color: map-get($lapis, 'base');
    font-weight: map-get($font-weights, 'bold');
  }

  &__billing-info {
    padding: 0 12px 8px;
    text-align: center;
    color: map-get($lapis, 'lighten-2');
  }

  &__price {
    font-size: 40px;
    color: map-get($lapis, 'base');
    font-weight: map-get($font-weights, 'black');
  }

  &__price-increased {
    font-size: 12px;
    color: map-get($begonia, 'lighten-1');
    font-weight: map-get($font-weights, 'regular');
    text-decoration: line-through;
    position: absolute;
  }

  &__strikethrough_price-container {
    display: inline-flex;
    flex-direction: column;
    top: -24px;
    position: relative;
    margin-bottom: -24px;
  }

  &__strikethrough_price {
    position: relative;
    right: 5px;
    bottom: -12px;
    font-size: 12px;
    color: map-get($begonia, 'lighten-1');
    text-decoration: line-through;
    font-weight: map-get($font-weights, 'bold');
  }

  &__currency {
    top: -22px;
    left: 2px;
  }

  &__billing {
    top: 4px;
    right: 8px;
  }

  &__feature-table {
    tr:hover {
      background: transparent !important;
    }

    td {
      border-bottom: none !important;
      height: 32px !important;
      padding: 0 8px !important;
    }

    .v-data-table__wrapper {
      overflow-x: hidden;
    }
  }

  &__feature {
    &-amount {
      background: map-get($neutral, 'lighten-2');
      border-radius: 4px;
      color: map-get($lapis, 'lighten-1');
      height: 24px;
      width: 80px;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  &__fullwidth-border {
    border-top: 1px solid #e6edf0;
    width: calc(100% + 9px);
    margin-bottom: 8px;
    margin-left: -5px;

    @media (min-width: 600px) and (max-width: 959px) {
      margin-bottom: 4px;
    }

    &--mobile {
      display: none;

      @media (min-width: 600px) and (max-width: 959px) {
        display: block;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
  }

  &__premium-support {
    color: map-get($lapis, 'lighten-2');
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: 18px;
  }

  &__select-btn--desktop {
    @media (min-width: 600px) and (max-width: 959px) {
      display: none;
    }
  }

  &__select-btn--mobile {
    display: none;

    @media (min-width: 600px) and (max-width: 959px) {
      display: flex;
    }
  }

  &__flex-header {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #1b294b;
    padding: 4px 0 6px 0;
    margin-bottom: -14px;
    @media (min-width: 600px) and (max-width: 959px) {
      margin-bottom: 0;
    }
  }
}
</style>
