import AppConfig from '@/AppConfig';
import Country from '@/Models/Static/Country';
import countries from '@/Models/Static/countries.json';
import DbipLookupService from '@/Services/DbipLookupService';
import StripeService from '@/Services/Subscription/StripeService';
import TaxLookupService from '@/Services/Tax/TaxLookupService';
import { RootState } from '@/store';
import * as Sentry from '@sentry/vue';
import { Module } from 'vuex';

export interface LocationState {
  continent_iso2: string;
  country_iso2: string;
  country_iso3: string;
  currency_code: string;
  tax_exempt: string;
  vat_rate: number;
  country: string;
  showLoading: any;
}

const dbipLookupService = new DbipLookupService();
const fallbackCountryIso2 = 'DE';

// FIXME remove this one, it does not need to be a store
const LocationStore: Module<LocationState, RootState> = {
  namespaced: true,

  // Will be filled via Dbip Service
  state: {
    continent_iso2: '',
    country_iso2: '',
    country_iso3: '',
    currency_code: '',
    tax_exempt: '',
    vat_rate: 0,
    country: '',
    showLoading: {
      loadingVatRate: false,
      loadingCountry: false,
    },
  },
  mutations: {
    loadingState(state, { loading, isLoading }: { loading: string; isLoading: boolean }) {
      state.showLoading[loading] = isLoading;
    },
    // Init the location via dbip service from API
    setLocation(state, country) {
      Object.assign(state, country);
    },
  },

  actions: {
    async setCountryManualByIso2({ commit }, countryIso2) {
      commit('loadingState', { loading: 'loadingCountry', isLoading: true });

      const country = countries.find((item: Country) => item.country_iso2 === countryIso2.toUpperCase());
      if (country) {
        commit('setLocation', country);
      }

      commit('loadingState', { loading: 'loadingCountry', isLoading: false });

      return Promise.resolve(country);
    },
    async detectCountryByIp({ commit }) {
      // Preset country by loading from API IP based country detection
      commit('loadingState', { loading: 'loadingCountry', isLoading: true });

      await dbipLookupService.init();
      let country = countries.find((item: Country) => item.country_iso2 === dbipLookupService.item.country);
      country =
        typeof country != 'undefined'
          ? country
          : countries.find((item: Country) => item.country_iso2 === fallbackCountryIso2);

      if (country) {
        commit('setLocation', country);
      }
      commit('loadingState', { loading: 'loadingCountry', isLoading: false });

      return Promise.resolve(country);
    },
    async detectCountryByGeolocation({ commit, getters }) {
      if (!getters.isGeoDetectionAvailable) {
        return Promise.resolve(null);
      }
      commit('loadingState', { loading: 'loadingCountry', isLoading: true });
      return new Promise<{
        lat: number;
        lng: number;
      }>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (e) => {
            // Capture friendly info error (so we can track it later) in this case since this is something that user haven't approved on their side
            Sentry.captureMessage(`Geolocation - [${e?.code || 0}] ${e?.message || 'General error'}`);
            reject(e);
          },
          { timeout: 10000 }
        );
      })
        .then((pos: { lat: number; lng: number }) => {
          return dbipLookupService.getLocationViaLatLong(pos.lat, pos.lng).then((result: any) => {
            const country = result
              ? countries.find((item: Country) => item.country_iso2 === result.data.country)
              : null;

            if (country) {
              commit('setLocation', country);
            }
            return Promise.resolve();
          });
        })
        .finally(() => commit('loadingState', { loading: 'loadingCountry', isLoading: false }));
    },
    async detectVatRate({ commit }, { customer, legalEntity }) {
      const { country, city, postal_code, state } = customer.address;
      const vat_id = customer.tax_id_data.value || '';

      if (!country || !postal_code || !legalEntity || AppConfig.getAPIBaseUrl() === '') {
        await commit('setLocation', {
          vat_rate: 0,
          tax_exempt: '',
        });
        return;
      }
      await commit('loadingState', { loading: 'loadingVatRate', isLoading: true });

      try {
        const taxResult = await TaxLookupService.lookup(country, city, postal_code, legalEntity, vat_id, state);

        const tax = {
          rate: taxResult.rate,
          exempt: taxResult.taxable === 'taxed' ? 'none' : taxResult.taxable,
        };

        // Merge new tax rate with location
        commit('setLocation', {
          vat_rate: tax.rate,
          tax_exempt: tax.exempt,
        });

        commit('loadingState', { loading: 'loadingVatRate', isLoading: false });
        return tax;
      } catch (e) {
        console.error('Failed loading tax rate with the following error: ' + e);
      }
    },
  },
  getters: {
    isGeoDetectionAvailable() {
      return 'geolocation' in navigator;
    },
    isEu(state) {
      return state.continent_iso2 === StripeService.CONTINENT_EU;
    },
    isInsideStatesWithoutVatIdField(state) {
      return StripeService.countriesWithoutVatIdFieldISO2.includes(state.country_iso2);
    },
    isVatIdNeeded(state, getters) {
      return getters.isEu && !getters.isInsideStatesWithoutVatIdField;
    },
    isTeamVatIdNeeded(state, getters, rootState) {
      const TEAM_COUNTRY = rootState.auth.team.country;
      const CONTINENT = countries.find((item: Country) => {
        return item.country_iso2 === TEAM_COUNTRY;
      });

      if (CONTINENT) {
        return CONTINENT.continent_iso2 === StripeService.CONTINENT_EU && TEAM_COUNTRY !== StripeService.COUNTRY_DE;
      }

      return false;
    },
  },
};

export default LocationStore;
