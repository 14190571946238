<template>
  <v-card flat class="plan-type-toggle">
    <v-btn-toggle v-model="localPlanType" mandatory dense rounded class="plan-type-toggle__button-group">
      <v-btn
        v-ripple="false"
        value="annual"
        class="plan-type-toggle__button"
        :class="{ active: localPlanType === 'annual' }"
      >
        {{ $t('fields.pricing_table.annual') }}
      </v-btn>
      <v-btn
        v-ripple="false"
        value="flexible"
        class="plan-type-toggle__button"
        :class="{ active: localPlanType === 'flexible' }"
      >
        {{ $t('fields.pricing_table.flexible') }}
      </v-btn>
    </v-btn-toggle>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class PlanTypeToggle extends Vue {
  @Prop({ type: String, required: true })
  planType!: 'annual' | 'flexible';
  localPlanType: 'annual' | 'flexible' = 'annual';
  @Watch('planType', { immediate: true })
  onPlanTypeChange(newValue: 'annual' | 'flexible') {
    this.localPlanType = newValue;
  }
  @Watch('localPlanType')
  onLocalPlanTypeChange(newValue: 'annual' | 'flexible') {
    this.$emit('plan-type-change', newValue);
  }
}
</script>

<style lang="scss" scoped>
.plan-type-toggle {
  display: inline-block;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px;
  &__button-group {
    background-color: #f0f0f0;
    padding: 4px;
    width: 245px;
    border-radius: 27.5px !important;
    justify-content: space-between;
    background: var(--color-neutral-100, #fdfeff);
    border: 1px solid var(--color-neutral-400, #d3dce0);
  }
  &__button {
    min-width: 120px;
    text-transform: none;
    font-weight: 600;
    width: 118px;
    padding: 16px;
    height: 32px;
    background: #fdfeff;
    //transition: background-color 0.3s, color 0.3s;
    border-style: none !important;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: var(--NumberScale2s24);
    letter-spacing: -0.01em;
    text-align: center;
    color: #1b294b;
    opacity: initial !important;
    border-radius: 27.5px;
    &:before {
      border-radius: 27.5px;
      background-color: transparent;
    }
    &.active {
      border-radius: 27.5px;
      gap: 10px;
      background-color: #294270;
      width: 118.51px;
      opacity: initial !important;
      color: #fdfeff;
    }
  }
}
</style>
