import AppConfig from '@/AppConfig';
import { User } from '@/classes/auth';

export default class OptimizelyService {
  public static trackEvent(eventName: string, tags: object | null = null): void {
    window['optimizely'] = window['optimizely'] || [];

    // Sends a tracking call to Optimizely for the given event name
    const data = {
      type: 'event',
      eventName: eventName,
    };

    if (tags) {
      Object.assign(data, { tags });
    }
    // @ts-ignore
    window['optimizely'].push(data);
  }

  public static isAbTestVariantActive(experimentId: string | null = null, variantId: string | null = null): boolean {
    if (experimentId === null || variantId === null) return false;
    if (this.optimizelyIsNotInitialized()) return false; // If Optimizely is not active/available

    const optimizelyState = window.optimizely.get('state');
    return (
      optimizelyState.getActiveExperimentIds().includes(experimentId) &&
      optimizelyState.getVariationMap()[experimentId].id == variantId
    );
  }

  public static optimizelyIsNotInitialized(): boolean {
    return window.optimizely === undefined || window.optimizely === null;
  }

  public static isUserOnAbtestVariantCNV2936(): boolean {
    return OptimizelyService.isAbTestVariantActive(
      AppConfig.getCNV2936ExperimentId(),
      AppConfig.getCNV2936VariationId()
    );
  }

  public static isUserOnAbtestVariantCNV3020(): boolean {
    return OptimizelyService.isAbTestVariantActive('26353010905', '26442340272');
  }

  public static isFlexPlanABTestEnabled(user: User): boolean {
    return (
      OptimizelyService.isFlexPlanAbTestVariant1Enabled(user) ||
      OptimizelyService.isFlexPlanAbTestVariant2Enabled(user) ||
      OptimizelyService.isFlexPlanAbTestVariant3Enabled(user)
    );
  }

  // This toggle allows the user to see the flex plan variant 1 (B variant). The two tests are used to evaluate separate currencies
  public static isFlexPlanAbTestVariant1Enabled(user: User): boolean {
    return user.team.ab_tests?.includes('CNV-3376') || user.team.ab_tests?.includes('CNV-2810');
  }

  // flex plan variant 2 (C variant)
  public static isFlexPlanAbTestVariant2Enabled(user: User): boolean {
    return user.team.ab_tests?.includes('CNV-3376-variant2') || user.team.ab_tests?.includes('CNV-2810-variant2');
  }

  // flex plan variant 3 (D variant)
  public static isFlexPlanAbTestVariant3Enabled(user: User): boolean {
    return user.team.ab_tests?.includes('CNV-3376-variant3') || user.team.ab_tests?.includes('CNV-2810-variant3');
  }

  // flex plan variant C+D
  public static isFlexPlanAbTestVariantCorD(user: User): boolean {
    return (
      OptimizelyService.isFlexPlanAbTestVariant2Enabled(user) || OptimizelyService.isFlexPlanAbTestVariant3Enabled(user)
    );
  }
}
